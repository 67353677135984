import { getBooksPk } from "@services/books";
import {
    getPageProfile,
    postPageProfile,
    putPageProfile,
    deletePageProfile,
} from "@services/page-profile";
import { getPNLSummary } from "@services/pnlSummary";
import deepClone from "deep-clone";
import {
    getLotDigits
} from "@services/functional-api";

const state = {
    socketUrl: document.querySelector("body").getAttribute("socketurl")
        ? document.querySelector("body").getAttribute("socketurl") +
          "/publisher-book-svc"
        : process.env.VUE_APP_SOCKET_BOOK,

    briefData: null,
    mainBookTitleDesc: {
        unrealized:
            "(Last Quote - EOD Price) * Volume, the End-of-Day Price is updated at 00:00:01 AM server time",
        realized:
            "Data extracted from MT4 server for Order Close Time 00:00:00 23:59:59 on current day",
        dailyNet: "Unrealised (EOD) + Realised",
    },
    mainBookTitle: { unrealized: 0, realized: 0, dailyNet: 0 },
    briefHeader: [
        {
            text: "Book",
            value: "book",
            align: "start",
            // width: 120,
            sortable: true,
            class: "bookHeader",
        },
        {
            text: "NPNL",
            value: "NPNL",
            align: "end",
            class: "NPNLHeader",
        },
        {
            text: "CPNL",
            value: "CPNL",
            align: "end",
            class: "CPNLHeader",
        },
        {
            text: "FPNL",
            value: "FPNL",
            align: "end",
            class: "FPNLHeader",
        },
        {
            text: "MTD PNL",
            value: "mtdPnl",
            align: " d-none",
            class: "mtdPnlHeader",
        },
        {
            text: "MTD Vol",
            value: "mtdVol",
            align: " d-none",
            class: "mtdVolHeader",
        },
    ],
    briefChildrenHeader: [
        {
            text: "Book",
            value: "book",
            align: "start",
            // width: 120,
            sortable: true,
        },
        {
            text: "NPNL",
            value: "NPNL",
            align: "end",
        },
        {
            text: "CPNL",
            value: "CPNL",
            align: "end",
        },
        {
            text: "FPNL",
            value: "FPNL",
            align: "end",
        },
        {
            text: "MTD PNL",
            value: "mtdPnl",
            align: " d-none",
        },
        {
            text: "MTD Vol",
            value: "mtdVol",
            align: " d-none",
        },
    ],
    pkData: [],
    pkHeaders: [
        { text: "Symbol", value: "symbol", align: "start" },
        { text: "Long", value: "longPositions", align: "end" },
        { text: "Short", value: "shortPositions", align: "end" },
        { text: "Net Lot", value: "net", align: "end" },
        {
            text: "Exposure",
            value: "symbolExposure",
            align: "end",
        },
        { text: "Daily Net", value: "dailyNet", align: "end" },
        {
            text: "Unrealized (EOD)",
            value: "floatingProfitEOD",
            align: "end",
        },
        {
            text: "Realized (EOD)",
            value: "closedProfitEOD",
            align: "end",
        },
        {
            text: "Unrealized (MT)",
            value: "floatingProfit",
            align: "end",
        },
        {
            text: "Realized (MT)",
            value: "closedProfit",
            align: "end",
        },
        {
            text: "Long VWAP",
            value: "weightedAvgLongPrice",
            align: "end",
        },
        {
            text: "Short VWAP",
            value: "weightedAvgShortPrice",
            align: "end",
        },
    ],
    subTableHeader: [
        { text: "Login", value: "login", align: "start",width: 140 },
        { text: "Long", value: "longPositions", align: "end" },
        { text: "Short", value: "shortPositions", align: "end" },
        { text: "Net Lot", value: "netPositions", align: "end" },
        {
            text: "Exposure",
            value: "exposure",
            align: "end",
        },
        { text: "Daily Net", value: "dailyNet", align: "end" },
        {
            text: "Unrealized (EOD)",
            value: "floatingProfitEOD",
            align: "end",
        },
        {
            text: "Realized (EOD)",
            value: "closedProfitEOD",
            align: "end",
        },
        {
            text: "Unrealized (MT)",
            value: "floatingProfit",
            align: "end",
        },
        {
            text: "Realized (MT)",
            value: "closedProfit",
            align: "end",
        },
        {
            text: "Long VWAP",
            value: "weightedAvgLongPrice",
            align: "end",
        },
        {
            text: "Short VWAP",
            value: "weightedAvgShortPrice",
            align: "end",
        },
    ],
    books: [],
    selectedBook: "",
    exposureData: null,
    csvFields: {
        Symbol: "symbol",
        Long: "longPositions",
        Short: "shortPositions",
        "Net Lot": "net",
        Exposure: "symbolExposure",
        "Daily Net": "dailyNet",
        "Unrealized (EOD)": "floatingProfitEOD",
        "Realized (EOD)": "closedProfitEOD",
        "Unrealized (MT)": "floatingProfit",
        "Realized (MT)": "closedProfit",
        "Long VWAP": "weightedAvgLongPrice",
        "Short VWAP": "weightedAvgShortPrice",
    },
    allBookCsvFields: {
        Book: "book",
        NPNL: "NPNL",
        CPNL: "CPNL",
        FPNL: "FPNL",
        "MTD PNL": "mtdPnl",
        "MTD Vol": "mtdVol",
    },
    isSocketConnected: false,
    allBookOptions: [],
    unwantedBooks: [],
    allProfile: [{ id: 0, text: "default", books: [] }],
    selectedProfile: {},
    editingDialog: null,
    updated: false,
    isBtnDisabled: false,
    dialogLoading: false,
    bookMap: null,
    PNLSummaryData: [],
    loading: false,
    selectedInterval: 1000,
    allBookIntervalOptions: [
        { key: "0.3s", value: 300 },
        { key: "0.5s", value: 500 },
        { key: "1s", value: 1000 },
        { key: "5s", value: 5000 },
        { key: "10s", value: 10000 },
        { key: "push", value: -1 },
    ],
    symbolSnapshotData: {},
    loginSnapshotData: {},
    homeTutorial: false,
    homeSymbolTutorial: false,
    homeSymbolSnapshotTutorial:false,
    homeLoginTradesTutorial: false,
    homeLoginInfoTutorial: false,
    homeLoginSnapshotTutorial: false,
    lotDigits: 2,
};

const actions = {
    /**
     * Action to get books from api
     *
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    getBooksPkAction({ commit, state }, params) {
        commit("UPDATE_BOOKS", []);
        commit("UPDATE_BRIEF_DATA", []);
        getBooksPk().then((res) => {
            commit("UPDATE_ALL_BOOK_OPTIONS", res.data);
        });
    },
    getLotDigitsAction({ commit }) {
        getLotDigits().then((res) => {
            commit("UPDATE_LOT_DIGITS", res.data);
        });
    },
    /**
     * Calculate all data needed for all books table
     *
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    calculateAllBooks({ commit, state }, data) {
        const result = state.briefData;
        const currentBook = data.book;

        const index = state.bookMap.findIndex(
            (item) => item.name == currentBook
        );

        const newRow = deepClone(result[index]);
        const last = result[index].FPNL;

        newRow.NPNL = (data.floatingProfitsEOD + data.closedProfitsEOD).toFixed(
            2
        );
        newRow.CPNL = data.closedProfitsEOD;

        newRow.FPNL = data.floatingProfitsEOD;
        newRow.color =
            data.floatingProfitsEOD > last
                ? "#27ae60"
                : data.floatingProfitsEOD < last
                ? "#c0392b"
                : "";
        if (JSON.stringify(newRow) !== JSON.stringify(result[index])) {
            state.briefData.splice(index, 1, newRow);
        }
    },
    getProfileAction({ commit }, data) {
        commit("UPDATE_BTN_STATUS", true);
        getPageProfile(data).then((res) => {
            if (res.data.length == 0) {
                commit("UPDATE_ALL_PROFILE", [
                    { id: 0, text: "default", books: [] },
                ]);
                commit("UPDATE_SELECTED_PROFILE", {
                    id: 0,
                    text: "default",
                    books: [],
                });
            } else {
                const returnedProfileList = res.data.map((profile) => {
                    return {
                        id: profile.id,
                        text: profile.profile_name,
                        books: JSON.parse(profile.value).unselected_books,
                    };
                });
                commit("UPDATE_ALL_PROFILE", returnedProfileList);

                const homeId = parseInt(localStorage.getItem("homeId"));
                if (homeId) {
                    if (
                        returnedProfileList.find((item) => item.id === homeId)
                    ) {
                        commit(
                            "UPDATE_SELECTED_PROFILE",
                            returnedProfileList.find(
                                (item) => item.id === homeId
                            )
                        );
                    } else {
                        commit(
                            "UPDATE_SELECTED_PROFILE",
                            returnedProfileList[0]
                        );
                    }
                } else {
                    commit("UPDATE_SELECTED_PROFILE", returnedProfileList[0]);
                }
            }
        });
    },
    putProfileAction({ commit }, data) {
        commit("UPDATE_LOADING", true);

        putPageProfile(data).then(() => {
            commit("UPDATE_EDITING_DIALOG", false);
            commit("CHANGE_UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    postProfileAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        postPageProfile(data).then(() => {
            commit("UPDATE_EDITING_DIALOG", false);
            commit("CHANGE_UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    deletePageProfileAction({ commit, state }, data) {
        deletePageProfile(data).then(() => {
            const remaining = state.allProfile.filter(
                (profile) => profile.id != data.id
            );
            if (remaining.length) {
                commit("UPDATE_ALL_PROFILE", remaining);
            } else {
                commit("UPDATE_ALL_PROFILE", [
                    { id: 0, text: "all", books: [] },
                ]);
                commit("UPDATE_EDITING_DIALOG", false);
            }
        });
    },
    getPNLSummaryAction({ commit }, data) {
        commit("UPDATE_TABLE_LOADING", true);
        commit("UPDATE_PNL_SUMMARY_DATA", []);
        getPNLSummary(data).then((res) => {
            res.data.forEach((item) => {
                item.realized_eod = item.realized_eod.toFixed(2);
                item.realized_mt4 = item.realized_mt4.toFixed(2);
            });
            commit("UPDATE_PNL_SUMMARY_DATA", res.data);
            commit("UPDATE_TABLE_LOADING", false);
        });
    },
    updateHomeTutorialAction({ state }, data){
        state.homeTutorial = data
    },
    updateHomeSymbolTutorialAction({ state }, data){
        state.homeSymbolTutorial = data
    },
    updateHomeSymbolSnapshotTutorialAction({ state }, data){
        state.homeSymbolSnapshotTutorial = data
    },
    updateLoginTradesTutorialAction({ state }, data){
        state.homeLoginTradesTutorial = data
    },
    updateLoginInfoTutorialAction({ state }, data){
        state.homeLoginInfoTutorial = data
    },
    updateLoginSnapshotTutorialAction({ state }, data){
        state.homeLoginSnapshotTutorial = data
    },
};

const mutations = {
    /**
     * Update main book title value
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MAIN_BOOK_TITLE(state, data) {
        state.mainBookTitle = data;
    },
    /**
     * Update books
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_BRIEF_DATA(state, data) {
        state.briefData = data;
    },
    /**
     * Update books
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_BOOKS(state, data) {
        state.bookMap = null;
        state.briefData = [];
        const result = [];
        for (const item of data) {
            if (!!item.children) {
                result.push({
                    name: item.book_name,
                    parent: null,
                    children: item.children,
                });
                for (const child of item.children) {
                    result.push({
                        name: child.book_name,
                        parent: item.book_name,
                        children: child.children || null,
                    });
                }
            } else {
                result.push({
                    name: item.book_name,
                    parent: null,
                    children: null,
                });
            }
        }
        state.bookMap = result;
        for (var i = 0; i < result.length; i++) {
            const preset = {
                book: result[i].name,
                NPNL: 0,
                CPNL: 0,
                FPNL: 0,
                lxp1: "None",
                vol: 0,
                mtdPnl: 0,
                mtdVol: 0,
                isChild: !!result[i].parent,
                hasChildren: !!result[i].children,
            };

            state.briefData.push(preset);
        }
        state.books = data;
    },
    /**
     * Update selectedBook
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_SELECTED_BOOK(state, data) {
        state.selectedBook = data;
    },
    /**
     * Update exposure data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_EXPOSURE_DATA(state, data) {
        state.exposureData = data;
    },
    UPDATE_PNL_SUMMARY_DATA(state, data) {
        state.PNLSummaryData = data;
    },
    UPDATE_SOCKET_CONNECTION_STATUS(state, data) {
        state.isSocketConnected = data;
    },
    UPDATE_ALL_BOOK_OPTIONS(state, data) {
        state.allBookOptions = data;
    },
    UPDATE_SELECTED_PROFILE(state, data) {
        if (data !== null && localStorage.getItem("homeId") !== data.id) {
            localStorage.setItem("homeId", data.id);
        }
        state.selectedProfile = data;
    },
    UPDATE_ALL_PROFILE(state, data) {
        localStorage.setItem("homeProfiles", JSON.stringify(data));
        state.allProfile = data;
        // state.isBtnDisabled = false;
    },
    UPDATE_EDITING_DIALOG(state, data) {
        state.editingDialog = data;
    },
    CHANGE_UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_BTN_STATUS(state, data) {
        state.isBtnDisabled = data;
    },
    UPDATE_LOADING(state, data) {
        state.dialogLoading = data;
    },
    UPDATE_ALL_BOOK_HEADER(state, data) {
        state.briefHeader.find((item) => item.value == data.target).align =
            data.alignment;
        state.briefChildrenHeader.find(
            (item) => item.value == data.target
        ).align = data.alignment;
    },
    UPDATE_TABLE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_SELECTED_INTERVAL(state, data) {
        state.selectedInterval = data;
    },
    UPDATE_SYMBOL_SNAPSHOT(state, data) {
        state.symbolSnapshotData = data;
    },
    UPDATE_LOGIN_SNAPSHOT(state, data) {
        state.loginSnapshotData = data;
    },
    UPDATE_LOT_DIGITS(state, data) {
        state.lotDigits = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
